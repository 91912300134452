import { render, staticRenderFns } from "./layout-contact-block-row.vue?vue&type=template&id=372c6cbd&scoped=true&"
import script from "./layout-contact-block-row.vue?vue&type=script&lang=js&"
export * from "./layout-contact-block-row.vue?vue&type=script&lang=js&"
import style0 from "./layout-contact-block-row.vue?vue&type=style&index=0&id=372c6cbd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "372c6cbd",
  null
  
)

export default component.exports